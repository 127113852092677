import React from "react";

import "./Home.css";
// import VerticalContact from "../../individual/VerticalContact/VerticalContact";
import congressLogo from "../../../assets/Logo 4ºC.png";

const Home = () => {
  return (
    <div className="home__container" id="home">
      <img
        src={congressLogo}
        alt="congress-logo"
        className="home__congress-logo"
      />
      <p className="home__welcome-text">
        El Col·legi d&#39;Economistes de Catalunya organitza el 4rt Congrés
        d&#39;Economia i Empresa de Catalunya 2025,&nbsp;
        <span style={{ fontStyle: "italic", fontWeight: "bolder" }}>
          Cap a una economia més digital i sostenible.
        </span>
        &nbsp;Participa en aquest fòrum transversal que agrupa reflexions al
        voltant de grans temes com la globalització, la macroeconomia,
        l’estructura empresarial, el capital humà, el sistema públic, la
        innovació, les finances, les infraestructures, la transició energètica,
        la sostenibilitat mediambiental i el futur de la professió d’economista.
        La sessió final i lectura de conclusions tindrà lloc el 20 de juny a les
        8.45h a l&#39;Auditori L’Illa de Barcelona, amb la participació del
        president de la Generalitat, Salvador Illa, el president del Parlament,
        Josep Rull, el ministre d’Indústria i Turisme, Jordi Hereu, i l’alcalde
        de Barcelona, Jaume Collboni. &nbsp;
        <a
          href="https://www.coleconomistes.cat/Eventos/wf_curso.aspx?IdMenu=d94ff862-99b6-463b-bde4-de56a1789838&Cod=18568&Idioma=ca-ES"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          Inscripció
        </a>
      </p>
    </div>
  );
};

export default Home;
