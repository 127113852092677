import React from "react";
import "./Organitzacio.css";
import Navbar from "../../individual/Navbar/Navbar";

const Organitzacio = ({ title, president, list, honor }) => {
  if (!honor) {
    return (
      <div className="organitzacio-apart__container">
        <Navbar />
        <div className="organitzacio-apart__header">
          {title !== undefined && <p>{title}</p>}
        </div>
        <div className="organitzacio-apart__wrapper">
          <div className="organitzacio-apart__wrapper-item wrapper-item-border">
            <p className="organitzacio-apart__wrapper-item__subtitle">
              {president !== undefined && president}
            </p>
            <p className="organitzacio-apart__wrapper-item__subtitle">
              MEMBRES:
            </p>
            <div className="organitzacio-apart__wrapper-item__list-container">
              {list !== undefined &&
                list.map((item, index) => <span key={index}>{item}</span>)}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="organitzacio-apart__container">
        <Navbar />
        <div className="organitzacio-apart__header">
          {title !== undefined && <p>{title}</p>}
        </div>
        <div className="organitzacio-apart__wrapper honor">
          <p
            className="organitzacio-apart__wrapper-item__subtitle"
            style={{ borderBottom: "2px solid black" }}
          >
            Govern i Administració Pública
          </p>
          <div className="organitzacio-apart__wrapper-item wrapper-item-border honor-wrapper">
            <div
              className="organitzacio-apart__wrapper-item__list-container"
              style={{ gap: "0.75rem" }}
            >
              {list !== undefined &&
                list.govern[0].map((item, index) => (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontWeight: "bold" }} key={index}>
                      {item.name}
                    </span>
                    <span key={index}>{item.title}</span>
                  </div>
                ))}
            </div>
            <div
              className="organitzacio-apart__wrapper-item__list-container"
              style={{ gap: "0.75rem" }}
            >
              {list !== undefined &&
                list.govern[1].map((item, index) => (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontWeight: "bold" }} key={index}>
                      {item.name}
                    </span>
                    <span key={index}>{item.title}</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="organitzacio-apart__wrapper honor">
          <p
            className="organitzacio-apart__wrapper-item__subtitle"
            style={{ borderBottom: "2px solid black" }}
          >
            Organitzacions econòmiques, acadèmiques i socials
          </p>
          <div className="organitzacio-apart__wrapper-item wrapper-item-border honor-wrapper">
            <div
              className="organitzacio-apart__wrapper-item__list-container"
              style={{ gap: "0.75rem" }}
            >
              {list !== undefined &&
                list.org[0].map((item, index) => (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontWeight: "bold" }} key={index}>
                      {item.name}
                    </span>
                    <span key={index}>{item.title}</span>
                  </div>
                ))}
            </div>
            <div
              className="organitzacio-apart__wrapper-item__list-container"
              style={{ gap: "0.75rem" }}
            >
              {list !== undefined &&
                list.org[1].map((item, index) => (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontWeight: "bold" }} key={index}>
                      {item.name}
                    </span>
                    <span key={index}>{item.title}</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="organitzacio-apart__wrapper honor">
          <p
            className="organitzacio-apart__wrapper-item__subtitle"
            style={{ borderBottom: "2px solid black" }}
          >
            Teixit empresarial
          </p>
          <div className="organitzacio-apart__wrapper-item wrapper-item-border honor-wrapper">
            <div
              className="organitzacio-apart__wrapper-item__list-container"
              style={{ gap: "0.75rem" }}
            >
              {list !== undefined &&
                list.empresa[0].map((item, index) => (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontWeight: "bold" }} key={index}>
                      {item.name}
                    </span>
                    <span key={index}>{item.title}</span>
                  </div>
                ))}
            </div>
            <div
              className="organitzacio-apart__wrapper-item__list-container"
              style={{ gap: "0.75rem" }}
            >
              {list !== undefined &&
                list.empresa[1].map((item, index) => (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontWeight: "bold" }} key={index}>
                      {item.name}
                    </span>
                    <span key={index}>{item.title}</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Organitzacio;
