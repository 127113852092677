export const organitzacioData = {
  organitzacio: [
    "Carles Puig de Travy (Degà del Col·legi d’Economistes de Catalunya)",
    "Àngels Fitó (vicedegana del Col·legi d’Economistes de Catalunya i rectora de la UOC)",
    "Antoni Detrell (director executiu del Col·legi d’Economistes de Catalunya)",
    "Ana Garcia Molina (presidenta de la Comissió d’Economia i Sostenibilitat del Col·legi d’Economistes de Catalunya i membre de la Junta de Govern)",
    "Anton Gasol (exdegà del Col·legi d’Economistes de Catalunya i patró de la Fundació FIBS)",
    "Àngel Hermosilla (secretari general del Col·legi d’Economistes de Catalunya)",
    "Jaume Menéndez Fernández (membre de la Junta de Govern)",
    "Martí Parellada (president del Comitè Científic i catedràtic d’Economia Aplicada de la UB)",
    "Carme Poveda (directora d'Anàlisi Econòmica de la Cambra de Comerç de Barcelona i presidenta de la Comissió d'Economia Industrial del Col·legi d’Economistes de Catalunya)",
    "Maurici Olivé (exgerent del Col·legi d’Economistes de Catalunya)",
    "Ester Oliveras (professora d’Economia de la UPF, presidenta de la Comissió d’Economia de la Igualtat i la Diversitat i membre de la Junta de Govern del Col·legi d’Economistes de Catalunya)",
    "Sofía Rodríguez (subdirectora general i Chief Economist de Banc Sabadell i vocal de la Junta de Govern)",
    "Xavier Segura (soci de Tracis i director de l'Enquesta de Situació Econòmica del Col·legi d’Economistes de Catalunya)",
    "Judit Vall (professora d’Economia de la UB i investigadora IEB i CRES-UPF)",
  ],
  cientific: [
    "Ramon Adell (UB)",
    "Pol Antràs (Harvard University)",
    "Núria Arimany (UdV)",
    "Núria Bosch (UB)",
    "Jordi Caballé (UAB)",
    "Albert Carreras (UPF)",
    "Montserrat Casanovas (UB)",
    "Antoni Castells (UB)",
    "Juan Francisco Corona (UAO)",
    "Maria Teresa Costa (UB)",
    "Eduard Cristóbal (UdLl)",
    "Antoni Elias (UPC)",
    "Elena Flores (CEPR)",
    "José Luis Gallizo (UdLl)",
    "Gemma Garcia (UB)",
    "José Garcia Quevedo (UB)",
    "Antoni Garrido (UB)",
    "Anna Garriga (UdG)",
    "Jordi Gual (IESE)",
    "Guillem López Casasnovas (UPF)",
    "Josep Maria Martorell (BSC)",
    "Antoni Mora (UIC)",
    "Josep Oliver (UAB)",
    "Vicente Ortún (UPF)",
    "Judith Panadés (UAB)",
    "Josep Maria Piqué (URL)",
    "Raül Ramos (UB)",
    "Josep Lluis Raymond (UAB)",
    "Imma Rodriguez-Ardura (UOC)",
    "Vicente Salas (FUNCAS)",
    "Antoni Terceño (UPC)",
    "Xavier Vives (IESE)",
  ],
  honor: {
    govern: [
      [
        {
          name: "Molt Honorable Sr. Salvador Illa i  Roca",
          title: "President de la Generalitat de Catalunya",
        },
        {
          name: "Molt Honorable Sr. Josep Rull i Andreu",
          title: "President del Parlament de Catalunya",
        },
        {
          name: "Honorable Sra. Alicia Romero i Llano",
          title: "Consellera d’Economia i Hisenda",
        },
        {
          name: "Honorable Sra. Silvia Paneque i Sureda",
          title: "Consellera de Territori, Habitatge i Transició Ecològica",
        },
        {
          name: "Honorable Sr. Miquel Sàmper i Rodríguez",
          title: "Conseller d’Empresa i Treball",
        },
        {
          name: "Excm. Sr. Jaume Collboni i Cuadrado ",
          title: "Alcalde de Barcelona",
        },
        {
          name: "Excma. Sra. Lluïsa Moret i Sabidó",
          title: "Presidenta de la Diputació de Barcelona",
        },
        {
          name: "Il·lm. Sr. Lluc Salellas i Vilar",
          title: "Alcalde de Girona",
        },
        {
          name: "Il·lm. Sr. Miquel Noguer i Planas",
          title: "President de la Diputació de Girona",
        },
      ],
      [
        {
          name: "Il·lm. Sr. Félix Larrosa i Piqué",
          title: "Alcalde de Lleida",
        },
        {
          name: "Il·lm. Sr. Joan Talarn i Gilabert  ",
          title: "President de la Diputació de Lleida",
        },
        {
          name: "Il·lm. Sr. Rubén Viñuales i Elias ",
          title: "Alcalde de Tarragona",
        },
        {
          name: "Il·lma. Sra. Noemí Llauradó i Sans ",
          title: "Presidenta de la Diputació de Tarragona",
        },
        {
          name: "Sr. José Alberto Carbonell i Camallonga",
          title: "President del Port de Barcelona",
        },
        {
          name: "Sr. Roger Loppacher i Crehuet",
          title: "President de l'Autoritat Catalana de la Competència",
        },
        {
          name: "Sr. Pere Navarro i Morera",
          title: "Delegat especial del Consorci de la Zona Franca",
        },
        {
          name: "Sr. Pau Relat i Vidal ",
          title: "President de Fira de Barcelona",
        },
        {
          name: "Sr. Miquel Salazar i Canalda",
          title: "Síndic Major de Comptes de Catalunya",
        },
      ],
    ],
    org: [
      [
        {
          name: "Sr. Jaume Alsina i Casalduero",
          title: "President de l'Associació Catalana de l'Empresa Familiar",
        },
        {
          name: "Rector Mgfc. Sr. Josep Eladi Baños",
          title: "President de la Xarxa Vives d’Universitats",
        },
        {
          name: "Sra. Maria Teresa Cabré i Castellví",
          title: "Presidenta de l’Institut d’Estudis Catalans",
        },
        { name: "Sr. Antoni Cañete i Martos", title: "President de PIMEC" },
        {
          name: "Sra. Ada Ferrer i Carbonell",
          title: "Presidenta de la Societat Catalana d'Economia",
        },
        {
          name: "Sra. Montserrat Casanovas i Ramon",
          title: "Presidenta de l'ACCID",
        },
        {
          name: "Sra. Aurora Catà i Sala",
          title:
            "VP de la 37 Edició de l'America's Cup i Secretària General del CIDOB",
        },
        { name: "Sra. Mercè Conesa i Pagés", title: "CEO de Barcelona Global" },
        {
          name: "Sr. Anton Costas Comesaña",
          title: "President del Consell Econòmic i Social",
        },
        {
          name: "Sr. Santiago Durán Domínguez",
          title:
            "President del Instituto de Contabilidad y Auditoría de Cuentas",
        },
        {
          name: "Sr. Jaume Guardiola i Romojaro",
          title: "President del Cercle d'Economia",
        },
      ],
      [
        { name: "Sr. Oriol Guixà Arderiu", title: "President de FEMCAT" },
        { name: "Sr. Josep Mateu i Negre", title: "President del RACC" },
        {
          name: "Sr. Enric Morist i Güell",
          title: "President de la Taula del Tercer Sector Social",
        },
        {
          name: "Sr. Javier Pacheco i Serradilla",
          title: "Secretari General de CCOO de Catalunya",
        },
        {
          name: "Sr. Antoni Pont Amenós",
          title: "President d'Honor de la Fundació Gresol",
        },
        {
          name: "Sr. Valentí Pich i Rosell",
          title: "President del Consejo General de Economistas de España",
        },
        {
          name: "Sr. Camil Ros i Duran",
          title: "Secretari General d’UGT de Catalunya",
        },
        {
          name: "Rector Mgfc. Sr. Joaquim Salvi i Mas",
          title: "President de l'Associació Catalana d'Universitats Públiques",
        },
        {
          name: "Sr. Josep Sánchez Llibre",
          title: "President de Foment del Treball Nacional",
        },
        {
          name: "Honorable Sr. Josef David Sánchez-Molina",
          title: "Secretari General del Cos Consular",
        },
        {
          name: "Excm. Sr. Josep Santacreu i Bonjoch",
          title: "President del Consell General de Cambres de Catalunya",
        },
        {
          name: "Sr. Josep Serveto i Puig",
          title: "President de la Trobada al Pirineu",
        },
      ],
    ],
    empresa: [
      [
        { name: "Sr. Nacho Abia i Buenache", title: "CEO de Grífols" },
        {
          name: "Sr. Ramon Agenjo i Bosch",
          title: "Vicepresident de la Fundació Damm",
        },
        {
          name: "Sr. Salvador Alemany i Mas",
          title:
            "President de SABA Infraestructures i del Gran Teatre del Liceu",
        },
        { name: "Sra. Elisabet Alier i Benages", title: "Presidenta d'Alier" },
        { name: "Sr. Anton Brufau i Niubó", title: "President de REPSOL" },
        {
          name: "Sr. Juan José Bruguera i Clavero",
          title: "President d'Inmobiliaria Colonial",
        },
        { name: "Sr. Joan Font i Fabregó", title: "President de Bonpreu" },
      ],
      [
        {
          name: "Sr. Isidre Fainé i Casas",
          title: "President de la Fundació La Caixa i de Criteria",
        },
        {
          name: "Sr. Tomàs Muniesa i Arantegui",
          title: "President de CaixaBank",
        },
        { name: "Sr. Josep Oliu i Creus", title: "President de Banc Sabadell" },
        { name: "Sr. Marco Patuano", title: "President de Cellnex" },
        { name: "Sr. Eloi Planes i Corts", title: "President de Fluidra" },
        { name: "Sr. Miquel Roca i Junyent", title: "President d'Agbar" },
        {
          name: "Sr. Angel Simon i Grimaldós",
          title: "Conseller Delegat de Criteria",
        },
        { name: "Sra. Marta Vidal i Ollé", title: "CEO de Vallformosa" },
      ],
    ],
  },
};
