import React from "react";
import Home from "../Home/Home.jsx";
import Eixos from "../Eixos/Eixos.jsx";
import Dates from "../Dates/Dates.jsx";
import Normes from "../Normes/Normes.jsx";
import OrganitzacióHome from "../OrganitzacióHome/OrganitzacióHome.jsx";
import Contacte from "../Contacte/Contacte.jsx";
import Patrocinadors from "../Patrocinadors/Patrocinadors.jsx";
import ReactPlayer from "react-player/youtube"; // Importa només el player de YouTube
import Colaboradors from "../Colaboradors/Colaboradors.jsx";
import "./HomePage.css";

const HomePage = () => {
  return (
    <>
      <Home />
      <div className="home-page-wrapper">
        <ReactPlayer
          url="https://www.youtube.com/watch?v=WjHro9aYHJ0"
          width="80%"
          height="500px"
          controls={true}
          playing={false}
          muted={false}
          loop={false}
        />
      </div>

      <Dates />
      <OrganitzacióHome />
      <Normes />
      <Eixos />
      <Contacte />
      <Patrocinadors />
      <Colaboradors />
    </>
  );
};

export default HomePage;
