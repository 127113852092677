import React from "react";

import Organitzacio from "../Organitzacio/Organitzacio.jsx";
import { organitzacioData } from "../../../data/organitzacioData.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "../HomePage/HomePage.jsx";
import Navbar from "../../individual/Navbar/Navbar.jsx";
import ScrollToTop from "../../individual/ScrollUp.jsx";
const RoutesComponent = () => {
  return (
    <Router>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="/organitzacio/organitzador"
          element={
            <Organitzacio
              president={
                "Oriol Amat, Catedràtic d'Economia Financera de la Universitat Pompeu Fabra i exdegà del Col·legi d'Economistes de Catalunya"
              }
              title={"Comitè Organitzador"}
              list={organitzacioData.organitzacio}
            />
          }
        />
        <Route
          path="/organitzacio/cientific"
          element={
            <Organitzacio
              president={
                "PRESIDENT Martí Parellada, catedràtic d’Economia Aplicada de la Universitat de Barcelona i col·legiat de Mèrit del Col·legi d’Economistes de Catalunya."
              }
              title={"Comitè Científic"}
              list={organitzacioData.cientific}
            />
          }
        />
        <Route
          path="/organitzacio/honor"
          element={
            <Organitzacio
              president={
                "President del Comitè d'Honor: Salvador Illa, president de la Generalitat de Catalunya"
              }
              title={"Comitè d'Honor"}
              list={organitzacioData.honor}
              honor={true}
            />
          }
        />
      </Routes>
    </Router>
  );
};

export default RoutesComponent;
