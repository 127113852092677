import React, { useState } from "react";
import SectionHeader from "../../individual/SectionHeader/SectionHeader";
import "./Dates.css";
import DatesItem from "../../individual/DatesItem/DatesItem";

const Dates = () => {
  const [closeAllCalendars, setCloseAllCalendars] = useState(false);

  return (
    <div
      className="dates__pater"
      id="datesclaus"
      onClick={() => {
        setCloseAllCalendars(true);
      }}
    >
      <div className="dates__container">
        <SectionHeader title="Dates" />
        <div className="dates__items-container">
          <DatesItem
            id={1}
            text="L'economia catalana dins el nou marc geoestratègic global"
            date="25/02/2025"
            closeAllCalendars={closeAllCalendars}
            setCloseAllCalendars={setCloseAllCalendars}
            link={
              "https://www.coleconomistes.cat/Eventos/wf_curso.aspx?IdMenu=d94ff862-99b6-463b-bde4-de56a1789838&Cod=18503&Idioma=ca-ES"
            }
          />
          <DatesItem
            id={2}
            text="El capital humà i el futur del treball"
            date="06/03/2025"
            closeAllCalendars={closeAllCalendars}
            setCloseAllCalendars={setCloseAllCalendars}
            link={
              "https://www.coleconomistes.cat/Eventos/wf_curso.aspx?IdMenu=d94ff862-99b6-463b-bde4-de56a1789838&Cod=18536&Idioma=ca-ES"
            }
          />
          <DatesItem
            id={3}
            text="Finances per una transició sostenible"
            date="25/03/2025"
            closeAllCalendars={closeAllCalendars}
            setCloseAllCalendars={setCloseAllCalendars}
            link={
              "https://www.coleconomistes.cat/Eventos/wf_curso.aspx?IdMenu=d94ff862-99b6-463b-bde4-de56a1789838&Cod=18513&Idioma=ca-ES"
            }
          />
          <DatesItem
            id={4}
            text="Sostenibilitat de les polítiques públiques"
            date="08/04/2025"
            closeAllCalendars={closeAllCalendars}
            setCloseAllCalendars={setCloseAllCalendars}
            link={
              "https://www.coleconomistes.cat/Eventos/wf_curso.aspx?IdMenu=d94ff862-99b6-463b-bde4-de56a1789838&Cod=18587&Idioma=ca-ES"
            }
          />
          <DatesItem
            id={5}
            text="Canvi climàtic i els seus impactes"
            date="09/04/2025"
            closeAllCalendars={closeAllCalendars}
            setCloseAllCalendars={setCloseAllCalendars}
            link={
              "https://www.coleconomistes.cat/Eventos/wf_curso.aspx?IdMenu=d94ff862-99b6-463b-bde4-de56a1789838&Cod=18531&Idioma=ca-ES"
            }
          />
          <DatesItem
            id={7}
            text="La contribució dels professionals en l'economia"
            date="15/05/2025"
            closeAllCalendars={closeAllCalendars}
            setCloseAllCalendars={setCloseAllCalendars}
            link={
              "https://www.coleconomistes.cat/Eventos/wf_curso.aspx?IdMenu=d94ff862-99b6-463b-bde4-de56a1789838&Cod=18546&Idioma=ca-ES"
            }
          />
          <DatesItem
            id={8}
            text="El sistema de ciència i innovació i el repte de la transformació digital"
            date="20/05/2025"
            closeAllCalendars={closeAllCalendars}
            setCloseAllCalendars={setCloseAllCalendars}
            link={
              "https://www.coleconomistes.cat/Eventos/wf_curso.aspx?IdMenu=d94ff862-99b6-463b-bde4-de56a1789838&Cod=18591&Idioma=ca-ES"
            }
          />
          <DatesItem
            id={9}
            text="La musculatura del sistema empresarial català"
            date="27/05/2025"
            closeAllCalendars={closeAllCalendars}
            setCloseAllCalendars={setCloseAllCalendars}
            link={
              "https://www.coleconomistes.cat/Eventos/wf_curso.aspx?IdMenu=d94ff862-99b6-463b-bde4-de56a1789838&Cod=18324&Idioma=ca-ES"
            }
          />
          <DatesItem
            id={10}
            text="Desafiament de la sostenibilitat"
            date="28/05/2025"
            closeAllCalendars={closeAllCalendars}
            setCloseAllCalendars={setCloseAllCalendars}
            link={
              "https://www.coleconomistes.cat/Eventos/wf_curso.aspx?IdMenu=d94ff862-99b6-463b-bde4-de56a1789838&Cod=18532&Idioma=ca-ES"
            }
          />
          <DatesItem
            id={10}
            text="Infraestructures i gestió sostenible del territori"
            date="03/06/2025"
            closeAllCalendars={closeAllCalendars}
            setCloseAllCalendars={setCloseAllCalendars}
            link={
              "https://www.coleconomistes.cat/Eventos/wf_curso.aspx?IdMenu=d94ff862-99b6-463b-bde4-de56a1789838&Cod=18605&Idioma=ca-ES"
            }
          />
          <DatesItem
            id={6}
            text="Determinants del context macroeconòmic"
            date="10/06/2025"
            closeAllCalendars={closeAllCalendars}
            setCloseAllCalendars={setCloseAllCalendars}
            link={
              "https://www.coleconomistes.cat/Eventos/wf_curso.aspx?IdMenu=d94ff862-99b6-463b-bde4-de56a1789838&Cod=18553&Idioma=ca-ES"
            }
          />
          <DatesItem
            id={11}
            text="Sessió final"
            date="20/06/2025"
            closeAllCalendars={closeAllCalendars}
            setCloseAllCalendars={setCloseAllCalendars}
            link={
              "https://www.coleconomistes.cat/Eventos/wf_curso.aspx?IdMenu=d94ff862-99b6-463b-bde4-de56a1789838&Cod=18568&Idioma=ca-ES"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Dates;
