import React from "react";
import "./OrganitzacioItem.css";
import { TbUserPentagon } from "react-icons/tb";
import { FaArrowRight } from "react-icons/fa";
import useDeviceWidth from "../../../custom-hooks/useDeviceWidth";

import { Link } from "react-router-dom";
const OrganitzacioItem = ({ text, marginBottom, path }) => {
  const { isMobile } = useDeviceWidth();
  return (
    <Link
      className={
        !marginBottom
          ? "organitzacio-item__container"
          : "organitzacio-item__container margin-bottom"
      }
      to={path}
    >
      <div className="organitzacio-item__icon-wrapper">
        <TbUserPentagon
          size={!isMobile ? 50 : 60}
          color="#003A83"
          className="organitzacio-item__icon"
        />
      </div>
      <div className="organitzacio-item__wrapper">
        <p className="organitzacio-item__title">{text}</p>
        <FaArrowRight
          className="organitzacio-item__arrow"
          size={20}
          color="#003A83"
        />
      </div>
    </Link>
  );
};

export default OrganitzacioItem;
