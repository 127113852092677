import React from "react";
import "./OrganitzacióHome.css";
import SectionHeader from "../../individual/SectionHeader/SectionHeader";
import OrganitzacioItem from "../../individual/OrganitzacioItem/OrganitzacioItem";
const OrganitzacióHome = () => {
  return (
    <div className="organitzacio__container" id="organitzacio">
      <SectionHeader halfWidth={true} title={"Organització"} />
      <div className="organitzacio__item-container">
        <OrganitzacioItem
          text={"Comitè d'Honor"}
          marginBottom={true}
          path={"organitzacio/honor"}
          honor={true}
        />
      </div>
      <div className="organitzacio__item-container">
        <OrganitzacioItem
          text={"Comitè organitzador"}
          marginBottom={true}
          path={"organitzacio/organitzador"}
        />
      </div>
      <div className="organitzacio__item-container">
        <OrganitzacioItem
          text={"Comitè Científic"}
          marginBottom={false}
          path={"organitzacio/cientific"}
        />
      </div>
    </div>
  );
};

export default OrganitzacióHome;
