import React, { useState, useRef, useEffect } from "react";
import "./DatesItem.css";
import { CiCalendarDate } from "react-icons/ci";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import useDeviceWidth from "../../../custom-hooks/useDeviceWidth";
import ca from "date-fns/locale/ca"; // Importa el fitxer de localització per al català

const DatesItem = ({
  text,
  date,
  closeAllCalendars,
  setCloseAllCalendars,
  link,
}) => {
  const [formatedDate, setFormatedDate] = useState(() => {
    const [dia, mes, any] = date.split("/"); // Separar la cadena de data per components
    return new Date(any, mes - 1, dia); // Crear un objecte de data amb els components
  });
  const { isMobile } = useDeviceWidth();
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [calendarDimensions, setCalendarDimensions] = useState({
    width: 0,
    height: 0,
  });
  const calendarRef = useRef(null);

  useEffect(() => {
    if (displayCalendar && calendarRef.current) {
      const { width, height } = calendarRef.current.getBoundingClientRect();
      setCalendarDimensions({ width, height });
    }
  }, [displayCalendar]);

  useEffect(() => {
    if (closeAllCalendars === true) {
      setDisplayCalendar(false);
    }
  }, [closeAllCalendars]);
  registerLocale("ca", ca);

  return (
    <div className="dates-item__container">
      <div className="dates-item__date-wrapper">
        <p className="dates-item__date">{date}</p>
        <CiCalendarDate
          color="black"
          size={!isMobile ? 20 : 50}
          className="calendar-icon"
          onClick={(event) => {
            event.stopPropagation(); // Impedeix que l'esdeveniment s'escampi cap amunt
            if (date !== undefined) {
              setCloseAllCalendars(false);
              setDisplayCalendar(true); // Mostra el calendari quan es clica l'ícon
            }
          }}
        />
        {displayCalendar && closeAllCalendars !== true && (
          <div className="calendar-wrapper" ref={calendarRef}>
            <div
              className="disable-overlay"
              style={{
                width: calendarDimensions.width,
                height: calendarDimensions.height,
              }}
              onClick={() => setDisplayCalendar(false)} // Tancar el calendari quan es fa clic a la capa desactivada
            />
            <DatePicker
              selected={formatedDate}
              onChange={(date) => {
                setFormatedDate(date);
                setDisplayCalendar(false); // Tanca el calendari quan es selecciona una data
              }}
              dateFormat="dd/MM/yyyy"
              locale="ca"
              showPopperArrow={false}
              inline // Mostra el calendari en línia sense un input associat
              excludeScrollbar
              open // Mantenir el calendari obert
            />
          </div>
        )}
      </div>
      <div className="dates-item__text">
        <a href={link} target="_blank" rel="noreferrer">
          <p>{text}</p>
        </a>
      </div>
    </div>
  );
};

export default DatesItem;
