import React from "react";
import SectionHeader from "../../individual/SectionHeader/SectionHeader";
import "./Colaboradors.css";
import logo1 from "../../../assets/fundacio-transparent.png";
const Colaboradors = () => {
  return (
    <div className="colaboradors__container" id="colaboradors">
      <SectionHeader title={"Col·laboradors"} />
      <div className="colaboradors__item-wrapper">
        <a
          href="https://www.coleconomistes.cat/Paginas/Ficha.aspx?IdMenu=6e1423ca-ead3-4b81-82a8-454c8be68a4d&Idioma=ca-ES"
          target="_blank"
          rel="noreferrer"
        >
          <img alt="colaborador" src={logo1} className="colaboradors__item" />
        </a>
      </div>
    </div>
  );
};

export default Colaboradors;
